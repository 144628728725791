<template>
    <div class="intro-image">
        <div class='img-container'>
            <div class="animation-container">
                <img class='image-intro' :src="episode.introImage" alt="">
                <a class='link' @click="playClick()" ></a>
            </div>
        </div>

        <div class='intro-text-container'>
            <div class='intro-text-box'>
                <div class='introtext title-02'>
                  <span v-html="episode.introImageText"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { episodes } from '../data'
import { Howl } from 'howler'
import '../data/customEases.js'

export default {
  name: 'IntroImage',
  data () {
    return {
      episode: {
        introImageText: episodes[this.$store.state.game.episode].introImageText,
        introImage: episodes[this.$store.state.game.episode].introImage
      },
      unlock: null
    }
  },
  mounted () {
    const buzz = new Howl({ src: 'audio/PhoneVibrate.mp3' })
    this.unlock = new Howl({ src: 'audio/iPhoneUnlockSound.mp3' })
    var that = this

    this.tlBuzz = new TimelineLite()
    this.tlBuzz
      .to('.animation-container', 0.25, {
        rotation: 1,
        ease: 'buzz',
        delay: 0.25,
        transformOrigin: '50% 50%',
        onStart: function () {
          buzz.play()
        }
      })
      .to('.animation-container', 0.25, { rotation: 1, ease: 'buzz', transformOrigin: '50% 50%' })
      .to('.animation-container', 0.25, { delay: 0.25, rotation: 0, ease: 'buzz', transformOrigin: '50% 50%' })
      .to('.animation-container', 0, { delay: 0.75, onComplete: function () { that.tlBuzz.restart() } })

    $('.link').hover(
      function () {
        TweenLite.to('.link', 0.3, { scale: 1.1, force3D: true })
      }, function () {
        TweenLite.to('.link', 0.3, { scale: 1, force3D: true })
      }
    )
  },
  methods: {
    playClick () {
      this.unlock.play()
      this.$router.push('/vraag')
    }
  },
  beforeUnmount () {
    this.tlBuzz.stop()
  }
}
</script>
