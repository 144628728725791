<template>
  <div class="overview">
    <Answers :type="'inline'" :popup="true" :mod="mod"></Answers>
    <div class="footer">
      <div class="footer-content">
        <div>
          <strong>{{ pointResponse }}</strong>
          Je hebt met deze vraag in totaal {{ episode.points }} punten verdiend!
          <a class="button black" @click.prevent="proceed">OK</a>
          <div class="points-container">
            <div class="points">
              <span class="count">{{ points }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Answers from '../components/Answers'

export default {
  name: 'Overview',
  components: {
    Answers
  },
  data () {
    return {
      episode: this.$store.state.game.episodes[this.$store.state.game.episode],
      response: 'GOED GEDAAN!',
      mod: 0,
      questionToggle: false
    }
  },
  methods: {
    slideoutQuestion () {
      TweenLite.to('.introtext', 0.4, {
        y: -300,
        opacity: 0,
        force3D: true,
        onComplete: () => {
          this.questionToggle = false
        }
      })
    },
    slideinQuestion () {
      TweenLite.fromTo(
        '.introtext',
        0.4,
        { y: -300, opacity: 0 },
        { y: '-50%', opacity: 1, force3D: true }
      )
    },
    toggleQuestion () {
      if (this.questionToggle) {
        this.setupQuestionToggle()
      } else {
        this.questionToggle = !this.questionToggle
      }
    },
    setupQuestionToggle () {
      TweenLite.to('.introtext', 0.4, {
        y: -300,
        opacity: 0,
        force3D: true,
        onComplete: () => {
          this.questionToggle = false
        }
      })
    },
    proceed () {
      const next = this.$store.state.game.episode + 1
      const mayProceed =
        this.$store.state.game.episodes[this.$store.state.game.episode].answers
          .length
      if (mayProceed) {
        this.$store.dispatch('UNLOCK_EPISODE', { episode: next }).then(() => {
          TweenLite.to('.main-bg-image', 0.5, {
            opacity: 0,
            onComplete: () => {
              this.$store.dispatch('CHANGE_EPISODE', { episode: next })
              this.$router.push('/tijdlijn')
            }
          })
        })
      } else {
        this.$router.push('/tijdlijn')
      }
    }
  },
  computed: {
    points: {
      get () {
        let points = 0
        for (const i in this.$store.state.game.episodes) {
          const episode = this.$store.state.game.episodes[i]
          points += episode.points
        }
        return points
      }
    },
    pointResponse: {
      get () {
        let pointResponse = ''
        if (this.episode.points >= (this.episode.actions.length * 10) / 2) {
          pointResponse = 'GOED GEDAAN!'
        }

        return pointResponse
      }
    }
  },
  beforeMount () {
    const correctEpisode =
      this.$store.state.game.episodes[this.$store.state.game.episode].answers
        .length
    if (!correctEpisode) {
      this.mod = 1
      this.episode =
        this.$store.state.game.episodes[this.$store.state.game.episode - 1]
    }
  },
  mounted () {
    $('.count').each(function () {
      $(this)
        .prop('Counter', 0)
        .animate(
          {
            Counter: $(this).text()
          },
          {
            duration: 1000,
            easing: 'swing',
            step: function (now) {
              $(this).text(Math.ceil(now))
            }
          }
        )
    })
  }
}
</script>
