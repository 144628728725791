<template>
  <div class="timeline">
    <div class="glide">
      <div class="glide__arrows" data-glide-el="controls">
        <button
          class="glide__arrow glide__arrow--left"
          data-glide-dir="<"
        ></button>
        <button
          class="glide__arrow glide__arrow--right"
          data-glide-dir=">"
        ></button>
      </div>
      <div class="border"></div>
      <div class="glide__track" data-glide-el="track">
        <div class="glide__slides">
          <div
            v-for="(episode, index) in episodes"
            :key="`episode_${index}`"
            class="glide__slide"
          >
            <div
              class="glide__slide-content"
              @click="startEpisode(index, glide.index)"
              :class="{
                'glide__slide-content--active': glide.index === index,
                'glide__slide-content--locked': episode.locked,
                'glide__slide-content--complete':
                  $store.state.game.episodes[index].answers.length ===
                  $store.state.game.episodes[index].actions.length,
              }"
            >
              <h4
                class="glide__title"
                v-if="
                  !(
                    $store.state.game.episodes[index].answers.length ===
                      $store.state.game.episodes[index].actions.length ||
                    episode.locked
                  )
                "
              >
                {{ episode.title }}
              </h4>
              <div class="glide__image">
                <img class="glide__thumbnail" :src="episode.thumbnail" />
                <div class="state"></div>
                <div
                  class="complete-state"
                  v-if="
                    $store.state.game.episodes[index].answers.length ===
                    $store.state.game.episodes[index].actions.length
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="glide__bullets" data-glide-el="controls[nav]">
        <button
          class="glide__bullet"
          :class="{ 'glide__bullet--locked': episodes[i - 1].locked }"
          :data-glide-dir="`=${i - 1}`"
          v-for="i in episodes.length"
          :key="`bullet_${i}`"
        ></button>
      </div>
    </div>
    <div class="points-container">
      <span> Totaal aantal punten: </span>{{ points }}
    </div>
  </div>
</template>

<script>
import Glide from '@glidejs/glide'
import { episodes } from '../data'

export default {
  name: 'Timeline',
  data () {
    return {
      episodes,
      activeEp: 0,
      glide: null
    }
  },
  beforeMount () {
    // checks what episodes are done yet
    const startAt = this.episodes.filter(
      (e) => e.answers.length === e.actions.length
    ).length

    this.glide = new Glide('.glide', {
      type: 'slider',
      gap: 122,
      startAt,
      rewind: false,
      focusAt: 'center',
      perView: 3,
      breakpoints: {
        768: { perView: 1, gap: 92 },
        1440: { perView: 3, gap: 62 }
      },
      peek: episodes.length
    })
  },
  mounted () {
    this.glide.mount()

    if (this.episodes.filter((e) => e.answers.length === e.actions.length).length === this.episodes.length) {
      this.$router.push('/uitspraak')
    }
  },
  methods: {
    startEpisode (episode, glideIndex) {
      // allows people to scroll without acting like a "click" function
      if (episode !== glideIndex) {
        return
      }

      // if not scrolling change route
      if (episode === this.$store.state.game.episode) {
        this.$router.push('/')
      } else if (this.$store.state.debugmode) {
        this.$store.dispatch('CHANGE_EPISODE', { episode: episode })
        this.$router.push('/')
      }
    }
  },
  computed: {
    points: {
      get () {
        let points = 0
        for (const i in this.$store.state.game.episodes) {
          const episode = this.$store.state.game.episodes[i]
          points += episode.points
        }
        return points
      }
    }
  }
}
</script>
