<template>
  <div class="congrats">
    <div class="half">
      <div class="points-container">
        <div class="content">
          <div class="video-container">
            <video
              id="video"
              :src="endtexts[text].video"
              v-if="endtexts[text].video"
              autoplay
            ></video>
          </div>
        </div>
        <div class="points count">
          <span>{{ points }}</span>
        </div>
      </div>
    </div>
    <div class="half">
      <div class="text-container">
        <div
          class="title-01 congrats-title"
          v-html="endtexts[text].header"
        ></div>
        <div class="title-02 congrats-sub-title">
          Je hebt {{ points }} punten gehaald.
        </div>
        <div class="copy-02 congrats-points-title">
          Je had {{ maxscore }} punten kunnen halen.
        </div>
      </div>
    </div>
    <div class="footer">
      <div v-html="endtexts[text].text" class="copy-01"></div>
      <div class="button-container">
        <a class="button" @click.prevent="restart">Speel opnieuw</a>
        <a class="button" href="https://www.om.nl" target="_BLANK">informatie</a>
      </div>
    </div>
    <div class="disclaimer" v-if="disclaimer">
      <div class="intro-text-container">
        <div class="intro-text-box">
          <span class="title">Credits</span>
          <br /><br />
          <strong>Ontwerp en realisatie:</strong><br />
          Openbaar Ministerie in samenwerking met Ijsfontein.
          <br /><br />
          <strong>Met dank aan:</strong><br />
          Officier van justitie Gabriëlle Hoppenbrouwers, Landelijk Team
          Forensische Opsporing van de Politie, Rechtbank Amsterdam. Beelden:
          Rechtbank Amsterdam, AVRO/TROS Opsporing Verzocht, Pieter Baan
          Centrum, Nederlands Forensisch Instituut, Politie en Openbaar
          Ministerie.
        </div>
        <span class="close" @click="disclaimer = false"></span>
      </div>
    </div>
  </div>
</template>

<script>
import enableInlineVideo from 'iphone-inline-video'
import { endtexts } from '../data'
import { config } from '../data/config'

export default {
  name: 'Congrats',
  data () {
    return {
      endtexts,
      text: 0,
      reverseRanges: null,
      disclaimer: false
    }
  },
  mounted () {
    const video_element = document.getElementById('video')
    enableInlineVideo(video_element)
    video_element.addEventListener('ended', () => {
      video_element.webkitExitFullScreen()
    })

    $('.count').each(function () {
      $(this)
        .prop('Counter', 0)
        .animate(
          {
            Counter: $(this).text()
          },
          {
            duration: 2000,
            easing: 'swing',
            step: function (now) {
              $(this).text(Math.ceil(now))
            }
          }
        )
    })

    if (this.maxscore - this.points <= 50) {
      this.text = 2
    } else if (this.maxscore - this.points <= 100) {
      this.text = 1
    } else {
      this.text = 0
    }
  },
  methods: {
    restart () {
      this.$store.dispatch('RESET_GAME').then(() => {
        this.$router.push('/')
      })
    }
  },
  computed: {
    ranges: {
      get () {
        const points = this.maxscore / endtexts.length
        const arr = []
        for (let entry in endtexts) {
          entry++
          arr.push(points * entry)
        }
        return arr
      }
    },
    maxscore: {
      get () {
        let actions = 0
        for (const episode in this.$store.state.game.episodes) {
          actions += this.$store.state.game.episodes[episode].actions.length
        }
        return actions * config.increment
      }
    },
    points: {
      get () {
        let points = 0
        for (const i in this.$store.state.game.episodes) {
          const episode = this.$store.state.game.episodes[i]
          points += episode.points
        }
        return points
      }
    }
  }
}
</script>
