<template>
  <div class="verdict">
    <div class="content">
      <div class="video-container">
        <img class="image-intro" src="/images/289.jpg" alt="" />
      </div>
    </div>
    <div class="intro-text-container">
      <div class="intro-text-box">
        <div class="introtext title-02">
          De rechter volgde de eis van de officier van justitie en legde de man
          8 jaar gevangenisstraf op en tbs met dwangverpleging. In hoger beroep
          werd de man veroordeeld tot 6 jaar gevangenisstraf met tbs.
        </div>
        <router-link to="/gefeliciteerd" class="button">OK</router-link>
        <br /><br />
      </div>
    </div>
  </div>
</template>

<script>
import '../data/customEases.js'

export default {
  name: 'verdictScreen',
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
</script>
