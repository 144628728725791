export const episodes = [
  // episode 1
  {
    id: 0,
    title: 'De officier van justitie wordt wakker gebeld.',
    thumbnail: './images/81.jpg',
    animation: [
      {
        file: './images/65.jpg',
        type: 'image',
        text: 'Een koude donkere februari-avond, 23:34',
        audio: './audio/ThunderRain.mp3'
      },
      {
        file: './images/71.jpg',
        type: 'image',
        text: 'Een eenzame automobilist',
        audio: ''
      },
      {
        file: './images/76.jpg',
        type: 'image',
        text: 'rijdt door het bos naar huis.',
        audio: ''
      },
      {
        file: './images/81.jpg',
        type: 'image',
        text: 'Er ligt een man op de weg!',
        audio: './audio/ThunderRain.mp3'
      },
      {
        file: './images/86.jpg',
        type: 'image',
        text: 'Zijn gezicht is onherkenbaar verminkt.',
        audio: ''
      },
      {
        file: './images/91.jpg',
        type: 'image',
        text: 'Nog een auto?',
        audio: './audio/ThunderRain.mp3'
      },
      {
        file: './images/96.jpg',
        type: 'image',
        text: 'De auto nadert snel.',
        audio: './audio/CarSkid.mp3'
      },
      {
        file: './images/101.jpg',
        type: 'image',
        text: 'De automobilist kan de auto net ontwijken!',
        audio: './audio/CarSkidBrake.mp3'
      },
      {
        file: './images/106.jpg',
        type: 'image',
        text: 'Hij belt 112.',
        audio: './audio/ThunderRain.mp3'
      },
      {
        file: './videos/Openingsscene.mp4',
        type: 'video'
      }
    ],
    introImageText:
      "Je hebt nachtdienst ('piket').<br>Je wordt gebeld door de politie en er wordt een opsporingsteam geformeerd.",
    introImage: './images/img-phone.png',
    introtext:
      "Jij bent officier van justitie. Je hebt nachtdienst ('piket'). Je wordt gebeld door de politie. Er wordt een opsporingsteam geformeerd. Jij geeft leiding aan het onderzoek. Wat doe je?",
    actions: [
      {
        id: 0,
        title: 'Aangeven welke sporen veiliggesteld moeten worden. ',
        answer: true,
        feedback:
          'Ja, het is van het grootste belang dat sporen op de plaats delict snel en nauwkeurig worden veiliggesteld. '
      },
      {
        id: 1,
        title: 'Opdracht geven de automobilist die heeft gebeld te horen.',
        answer: true,
        feedback: 'Ja, alles wat hij heeft gezien kan van belang zijn. '
      },
      {
        id: 2,
        title:
          'Toestemming vragen voor een telefoontap aan de rechter-commissaris (de rechter die beslist over welke opsporingsmiddelen ingezet mogen worden).',
        answer: false,
        feedback: 'Nee, er is geen telefoon die je zou willen tappen.'
      },
      {
        id: 3,
        title:
          'Beslag leggen op het lichaam van het slachtoffer. En het Nederlands Forensisch Instituut (NFI) opdracht geven onderzoek te doen naar de doodsoorzaak en eventuele sporen. ',
        answer: true,
        feedback:
          'Ja, ook al lijkt de doodsoorzaak voor de hand te liggen, je moet het zeker weten. Bovendien kan het NFI zoeken naar sporen van de dader. '
      },
      {
        id: 4,
        title: 'Opdracht geven de plaats delict te observeren. ',
        answer: false,
        feedback:
          'Nee, de dader is gevlucht en de plaats delict is helemaal onderzocht. Er is geen reden om daar verder nog iets te doen. '
      },
      {
        id: 5,
        title: 'Opdracht geven voor het doen van een buurtonderzoek in het nabijgelegen dorp.',
        answer: true,
        feedback:
          'Ja, misschien heeft iemand op de tijd van het delict iets gezien of gehoord. '
      },
      {
        id: 6,
        title: 'Opsporing Verzocht inschakelen. ',
        answer: false,
        feedback:
          'Nee,  je bent nog vol in onderzoek. Opsporing Verzocht kun je als dat nodig is later inschakelen als andere opsporingsmiddelen niets hebben opgeleverd.'
      }
    ]
  },
  // episode 2
  {
    id: 1,
    title: 'Plaats delict. ',
    thumbnail: './images/145.jpg',
    animation: [
      {
        file: './videos/plaatsdelict.mp4',
        type: 'video'
      }
    ],
    introtext:
      'De automobilist in het bos heeft het kenteken van de auto niet kunnen zien. Maar hij heeft wel gezien dat het om een kleine, rode auto gaat met een flinke deuk in de motorkap. De koplampen waren uit. De achterlichten doen hem denken aan een Renault Twingo. Wat doe je?',
    actions: [
      {
        id: 0,
        title:
          'Je laat alle garagehouders in de buurt bellen om te vragen of ze een dergelijke auto  herkennen. ',
        answer: false,
        feedback:
          'Nee, het is maar de vraag of dit wat oplevert en zo komt de informatie naar buiten die je voorlopig liever binnen het onderzoek houdt. '
      },
      {
        id: 1,
        title:
          'Je geeft de politie opdracht om in het interne systeem van de politie te zoeken naar een kleine, rode auto met schade aan de motorkap. ',
        answer: true,
        feedback: "Ja, hierdoor komen drie mogelijke auto's naar voren. "
      }
    ]
  },
  // episode 3
  {
    id: 2,
    title: 'Het Nederlands Forensisch Instituut',
    thumbnail: './images/156.jpg',
    animation: [
      {
        file: './images/156.jpg',
        type: 'image',
        text: 'Het Nederlands Forensisch Instituut',
        audio: ''
      },
      {
        file: './videos/forensisch.mp4',
        type: 'video'
      }
    ],
    introtext:
      'Het Nederlands Forensisch Instituut (NFI) rapporteert de doodsoorzaak: excessief geweld op het hoofd met een hol of rond voorwerp. Wat doe je met deze informatie?',
    actions: [
      {
        id: 0,
        title:
          'Je brengt dit in een persbericht naar buiten. Misschien dat iemand het wapen kent. ',
        answer: false,
        feedback:
          'Nee, zo geef je informatie weg die alleen bij de dader bekend is. '
      },
      {
        id: 1,
        title: 'Je houdt dit geheim.',
        answer: true,
        feedback:
          'Ja, dat is daderinformatie en kan van belang zijn voor de bewijsvoering. '
      },
      {
        id: 2,
        title: 'Je geeft het lichaam vrij.',
        answer: true,
        feedback:
          'Ja, als het NFI-onderzoek is afgerond, beslist de officier van justitie dat het lichaam aan de familie overgedragen kan worden.'
      }
    ]
  },
  // episode 4
  {
    id: 3,
    title: 'Bewijsmateriaal?',
    thumbnail: './images/169.jpg',
    animation: [
      {
        file: './images/169.jpg',
        type: 'image',
        text: 'Bewijsmateriaal?',
        audio: ''
      }
    ],
    introtext:
      "Enkele dagen later wordt aangifte gedaan van diefstal van een auto. Het gaat om één van de drie auto's die in het onderzoek naar voren zijn gekomen. De auto wordt gevonden, drijvend in een sloot. Wat doe je?",
    actions: [
      {
        id: 0,
        title: 'Je laat de auto vernietigen.',
        answer: false,
        feedback: 'Nee, misschien vernietig je wel sporen.'
      },
      {
        id: 1,
        title:
          'Je neemt de auto in beslag en geeft opdracht deze op sporen te onderzoeken.',
        answer: true,
        feedback:
          'Ja, het is het beste om zo snel mogelijk naar sporen te zoeken.'
      },

      {
        id: 2,
        title: 'Je neemt de auto in beslag en laat deze ergens opslaan.',
        answer: false,
        feedback:
          'Nee, als je besluit de auto te bewaren voor onderzoek, dan is het beter dit onderzoek gelijk te doen.'
      }
    ]
  },
  // episode 5
  {
    id: 4,
    title: 'De officier van justitie leidt het onderzoek.',
    thumbnail: './images/183.jpg',
    animation: [
      {
        file: './images/183.jpg',
        type: 'image',
        text: 'De officier van justitie leidt het onderzoek.',
        audio: ''
      }
    ],
    introtext: 'Wat doe je om te weten te komen wie het slachtoffer is?',
    actions: [
      {
        id: 0,
        title: 'Je laat DNA afnemen.',
        answer: false,
        feedback:
          'In dit stadium van het onderzoek is het niet nodig om DNA-onderzoek te doen voor het vaststellen van de identiteit.'
      },
      {
        id: 1,
        title:
          'Je laat de politie nagaan of iemand als vermist is opgegeven die past bij het signalement. ',
        answer: true,
        feedback:
          'Ja, dit wil vaak helpen. In deze zaak levert het helaas niets op. '
      },
      {
        id: 2,
        title:
          'Je schakelt opsporingsprogramma’s in waarbij je mensen oproept zich te melden als ze iets verdachts hebben gezien.',
        answer: false,
        feedback:
          'Nee, soms gebeurt dat wel maar in dit geval zijn er nog genoeg mogelijkheden om verder te rechercheren. '
      },
      {
        id: 3,
        title: 'Je laat onderzoek doen naar vingerafdrukken. ',
        answer: true,
        feedback:
          'Ja, op deze manier komt aan het licht dat het gaat om een man die bij het Leger des Heils woont. Hij is die avond het laatst tussen acht en negen uur thuis gezien. '
      }
    ]
  },
  // episode 6
  {
    id: 5,
    title: 'Overleg met politie. ',
    thumbnail: './images/202.jpg',
    animation: [
      {
        file: './images/202.jpg',
        type: 'image',
        text: 'Overleg met politie. ',
        audio: ''
      }
    ],
    introtext: 'Wat doe je nu bekend is wie het slachtoffer is?',
    actions: [
      {
        id: 0,
        title:
          'Je maakt de identiteit bekend in de hoop dat mensen die hem kennen zich melden. ',
        answer: false,
        feedback:
          'Nee, er zijn allerlei andere manieren om nu verder te komen in het onderzoek.'
      },
      {
        id: 1,
        title:
          'Je laat de politie de familie opsporen, zodat ze hem kunnen begraven. ',
        answer: true,
        feedback:
          'Ja, ook dat hoort bij het werk van de politie. En misschien heeft de familie belangrijke informatie.'
      },
      {
        id: 1,
        title:
          'Je laat de politie zijn omgeving in beeld brengen en laat vrienden en kennissen horen. ',
        answer: true,
        feedback: 'Ja, dit kan nieuwe aanwijzingen opleveren. '
      }
    ]
  },
  // episode 7
  {
    id: 6,
    title: 'Een doorbraak!',
    thumbnail: './images/218.jpg',
    animation: [
      {
        file: './images/218.jpg',
        type: 'image',
        text: 'Een doorbraak!',
        audio: ''
      }
    ],
    introtext:
      'Eén van de kennissen van het slachtoffer is ook de eigenaar van de in de sloot gevonden rode auto. Bovendien kwam deze man vaker in het bewuste bos met zijn hond en heeft hij eerder geweld gepleegd. Wat doe je',
    actions: [
      {
        id: 0,
        title:
          'Je merkt de man aan als verdachte en vraagt de rechter-commissaris om zijn telefoon te tappen.',
        answer: true,
        feedback:
          'Ja, misschien praat de verdachte via de telefoon over het misdrijf.'
      },
      {
        id: 1,
        title: 'Je sluit het onderzoek en stuurt de man een dagvaarding. ',
        answer: false,
        feedback:
          'Nee, veel te vroeg. Je hebt een verdenking maar nog geen hard bewijs. '
      },
      {
        id: 2,
        title: 'Je laat de man aanhouden. ',
        answer: false,
        feedback:
          'Nee, veel te vroeg. Je hebt een verdenking maar nog geen hard bewijs. '
      },
      {
        id: 3,
        title:
          "Je schakelt opsporingsprogramma's in waarbij je mensen oproept zich te melden als ze iets verdachts hebben gezien.",
        answer: true,
        feedback:
          'Ja, nu is het een goed moment om te vragen of mensen iets verdacht hebben gezien.'
      }
    ]
  },
  // episode 8
  {
    id: 7,
    title: 'Het bekende tv-programma wordt ingeschakeld. ',
    thumbnail: './images/231.jpg',
    animation: [
      {
        file: './images/231.jpg',
        type: 'image',
        text: 'Het bekende tv-programma wordt ingeschakeld. ',
        audio: ''
      },
      {
        file: './videos/opsporing.mp4',
        type: 'video'
      }
    ],
    introtext:
      'Er komen tips binnen. Iemand noemt de verdachte en weet van de beschadigde auto. De verdachte belt zelf ook en zegt dat de beste vriend van het slachtoffer het heeft gedaan. Hierbij vertelt hij details die alleen de dader kan weten. Wat doe je?',
    actions: [
      {
        id: 0,
        title: 'Je laat de verdachte aanhouden en confronteert hem hiermee. ',
        answer: false,
        feedback:
          'Nee, de zaak wordt wel sterker, maar er is nog te weinig bewijs om de verdachte vast te kunnen houden. Het onderzoek moet verder om meer bewijs te vinden. '
      },
      {
        id: 1,
        title:
          'Je laat de verdachte niet aanhouden, maar je geeft de politie wel de opdracht dit allemaal nauwkeurig vast te leggen en te bewaren. ',
        answer: true,
        feedback: 'Ja, je weet dat dit van groot belang zal zijn. '
      }
    ]
  },
  // episode 9
  {
    id: 8,
    title: 'Getuigen melden zich. ',
    thumbnail: './images/242.jpg',
    animation: [
      {
        file: './images/242.jpg',
        type: 'image',
        text: 'Getuigen melden zich. ',
        audio: ''
      }
    ],
    introtext:
      'Twee kennissen van zowel het slachtoffer als de verdachte die eerder niets wilden vertellen melden zich op het bureau. Beiden staan bekend als alcoholist. Zij vertellen dat de verdachte op de avond van de moord bij hen was en om kwart voor tien weg ging met een ijzeren staaf. Later was hij met bebloede handen en zonder die ijzeren staaf teruggekomen. Hij had gezegd dat hij de verkrachter van zijn kinderen had doodgeslagen. De volgende dag maakte hij zijn auto grondig schoon. Wat doe je?',
    actions: [
      {
        id: 0,
        title: 'Je laat de verdachte aanhouden. ',
        answer: true,
        feedback:
          'Ja, er is nu voldoende bewijs om de man niet alleen aan te houden maar ook voor te geleiden bij de rechter-commissaris. Die beslist of de man na drie dagen bij de politie langer vast moet zitten, in voorarrest. Als er te weinig bewijs is, komt hij weer vrij en dan is er vluchtgevaar. '
      },
      {
        id: 1,
        title: 'Je laat de verdachte observeren.',
        answer: false,
        feedback:
          'Nee, je hebt nu wel voldoende bewijs om hem aan te houden. Observeren zou een verspilling zijn. '
      },
      {
        id: 2,
        title: 'Je zoekt meer ondersteuning voor deze verklaring.',
        answer: true,
        feedback:
          'Ja, dat is wel nodig. De advocaat van de verdachte vindt de verklaring van de twee kennissen te onbetrouwbaar omdat de mannen verslaafd zijn aan alcohol. '
      }
    ]
  },
  // episode 10
  {
    id: 9,
    title: 'De zitting.',
    thumbnail: './images/255.jpg',
    animation: [
      {
        file: './videos/aanhoudingen.mp4',
        type: 'video'
      },
      {
        file: './images/255.jpg',
        type: 'image',
        text: 'De zitting. ',
        audio: ''
      },
      {
        file: './videos/rechtzaak.mp4',
        type: 'video'
      }
    ],
    introtext:
      'Je hebt de verdachte gedagvaard. Hij moet dus voor de rechter verschijnen. Welk bewijs voer je tegen hem op?',
    actions: [
      {
        id: 0,
        title: 'De verklaring van de twee kennissen. ',
        answer: true,
        feedback:
          'Ja, de advocaat kan dan wel zeggen dat de verklaring niet betrouwbaar is, de mannen zijn uit eigen initiatief met het verhaal gekomen. '
      },
      {
        id: 1,
        title: 'De gegevens uit het technisch onderzoek over de auto.',
        answer: true,
        feedback:
          'Ja, en dit in combinatie met de verklaring van de automobilist.'
      },
      {
        id: 2,
        title: 'De verklaring van de verdachte zelf.',
        answer: true,
        feedback:
          'Ja waarbij het wel zo is dat de verdachte geen antwoord hoeft te geven op de vragen die hem gesteld worden.'
      },
      {
        id: 3,
        title: 'De tapgesprekken.',
        answer: true,
        feedback:
          'Ja, hierbij kan het om zowel belastend als ontlastend materiaal gaan. In de taps in deze zaak zegt de verdachte tegen een kennis dat er "een beloning op mijn kop" staat en ook bekent hij te hebben geslagen met een ijzeren staaf.'
      }
    ]
  },
  // episode 11
  {
    id: 10,
    title: 'Pieter Baan Centrum onderzoekt.',
    thumbnail: './images/270.jpg',
    animation: [
      {
        file: './images/270.jpg',
        type: 'image',
        text: 'Pieter Baan Centrum onderzoekt.',
        audio: ''
      }
    ],
    introtext:
      'Je hebt de rechtbank gevraagd om de man psychisch te laten onderzoeken in het Pieter Baan Centrum. Het doel is om te weten te komen of de man geheel of verminderd toerekeningsvatbaar is. Het Pieter Baan Centrum stelt vast dat de man een persoonlijkheidsstoornis heeft en verminderd toerekeningsvatbaar is. De maximale straf voor moord is levenslang. Welke straf vind je passend?',
    actions: [
      {
        id: 0,
        title: 'Een taakstraf met verplichte bezoeken aan de reclassering.',
        answer: false,
        feedback: 'Nee, dit is een te lage straf volgens het OM.'
      },
      {
        id: 1,
        title: 'Een gevangenisstraf van zes jaar en tbs met dwangverpleging.',
        answer: true,
        feedback:
          'Ja, dit is mogelijk, in hoger beroep is de verdachte hiertoe door het Gerechtshof veroordeeld. '
      },
      {
        id: 2,
        title: 'Acht jaar gevangenisstraf en tbs met dwangverpleging.',
        answer: true,
        feedback:
          'Ja, de man moet volgens het OM niet alleen een gevangenisstraf krijgen maar ook behandeld worden voor zijn psychische problemen. '
      },
      {
        id: 3,
        title: 'Een gevangenisstraf van 35 jaar.',
        answer: false,
        feedback:
          'Nee, na levenslang is 30 jaar de hoogste gevangenisstraf die iemand kan krijgen.'
      },
      {
        id: 4,
        title: 'Een levenslange gevangenisstraf.',
        answer: false,
        feedback:
          'Nee, levenslang wordt niet zo vaak geëist. In dit geval telt mee dat de man een persoonlijkheidsstoornis heeft. '
      }
    ]
  }
  // episode 12
  // {
  //     id: 11,
  //     title: 'De rechtzaak',
  //     thumbnail: './images/289.jpg',
  //     animation: [
  //         {
  //             file: './images/289.jpg',
  //             type: 'image',
  //             text: 'De rechtzaak'
  //         }
  //     ],
  //     introtext: 'De rechtzaak',
  //     actions: [
  //         {
  //             id: 0,
  //             title: 'Moet de verdachten veroordeeld worden?',
  //             answer: true,
  //             feedback: 'Ja'
  //         }
  //     ]
  // }
]

export const endtexts = [
  {
    id: 0,
    header: 'Helaas',
    text: 'Je bent nog niet echt een goede speurneus. De kans is klein dat jij in dit geval de juiste dader achter slot en grendel had gekregen. Wil je meer weten over het werk van de officier van justitie kijk dan eens op <a href="https://www.om.nl" target="_BLANK">www.om.nl</a>.',
    video: './videos/Slechtescore.mp4'
  },
  {
    id: 1,
    header: 'Gefeliciteerd',
    text: 'Je hebt het speurwerk wel in de vingers, maar maakt af en toe een fout. En dat kan er zomaar voor zorgen dat de dader ontkomt of dat het bewijs niet geldig is in de rechtszaal. Verdiep je anders eens in het werk van de officier op <a href="https://www.om.nl" target="_BLANK">www.om.nl</a>.',
    video: './videos/Matigescore.mp4'
  },
  {
    id: 2,
    header: 'Gefeliciteerd',
    text: 'Heel goed gedaan, misdadigers zijn niet veilig wanneer jij de leiding hebt over het opsporingsonderzoek! Je zou een goede officier van justitie zijn. Kijk voor meer informatie op <a href="https://www.om.nl" target="_BLANK">www.om.nl</a>.',
    video: './videos/Goedescore.mp4'
  }
]
